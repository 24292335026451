@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mr-40 {
    margin-right: 40px;
}

.location-search  .select-location > div {
  border: none;
  box-shadow: none;
}
.modal-location > div {
 @apply !bg-desertStorm border-0 hover:!border-0
}
.modal-location .css-t3ipsp-control:hover{
  border: none;
  box-shadow: none;
  @apply border-transparent
}
.search-bar .select-location > div {
  border: none;
  box-shadow: none;
  min-height: 22px;
  background-color: transparent;
}

.search-bar .select-location > div + div,
.location-search .select-location > div + div
{
  margin-top: 1.3rem;
  width: 350px;
  background-color: #fff;
  @apply text-boulder
}

.search-bar .select-location > div + div,
.location-search .select-location > div + div {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.search-bar .select-location > div:nth-child(3) > div:first-child > div:first-child,
.search-bar .select-location > div:nth-child(3) > div:first-child > div:nth-child(2),
.location-search .select-location > div:nth-child(3) > div:first-child > div:first-child,
.location-search .select-location > div:nth-child(3) > div:first-child > div:nth-child(2)
{
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  cursor: text;
}

.search-bar .select-location > div > div:first-child {
  padding-top: 0;
  padding-bottom: 0;
}

.search-bar .input-data-picker {
  padding-top: 0;
  padding-bottom: 0;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.search-bar .input-data-picker input::placeholder {
  color: #717171;
}

.search-bar .ant-picker input::placeholder {
  color: #717171;
}

.search-bar .ant-picker {
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  background: transparent;
  width: 100%;
  min-width: 240px;
  justify-content: space-between;
}

.search-bar .ant-picker-input {
  width: 50%;
}

.search-bar .ant-picker-suffix,
.search-bar .ant-picker-active-bar {
  display: none;
}

.search-bar .ant-picker-range-separator {
  display: none;
}

.search-bar-mobile .ant-modal-content {
  border-radius: 10px;
}

.css-1dimb5e-singleValue .location-icon {
  display: none;
}

.ant-modal-root .ant-modal-close {
  background: #fff;
  border-radius: 100%;
  right: 10px;
  top: 10px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.ant-modal-root .ant-modal-close-x {
  display: flex;
  align-items: center;
  color: #000;
  justify-content: center;
  font-size: 18px;
  width: 45px;
  height: 45px;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  border-radius: 50px;
  @apply border-1 border-black
}
.gm-style-iw-d {
  overflow: auto !important;
}
.gm-style-iw-chr {
  display: none !important;
}
.ant-picker-panel-container {
  @apply !w-fit
}
.product-location-map .gm-style .gm-style-iw-c {
  box-shadow: inherit;
  @apply border-0 bg-transparent rounded-full
}
.pin-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3; /* 30% opacity */
  z-index: -1; /* Place it behind the content */
  @apply bg-supernova
}

/* .gm-style .gm-style-iw-d {
  padding-top: 4px;
  padding-bottom: 0;
  margin-bottom: -5px;
  padding-left: 4px;
  margin-right: -5px;
} */

.product-filter .select-options > div {
  min-height: 41px;
}

.custom-select-box .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 20px !important;
}

.custom-select-box .ant-select-selector .ant-select-selection-placeholder {
  font-size: 14px;
  color: #717171;
}

.custom-select-box .ant-select-selector .ant-select-selection-item {
  font-size: 14px;
}

.product-filter .ant-picker-separator {
  display: none;
}

.product-filter .ant-select-selector {
  border-radius: 20px !important;
}

.product-filter .select-options >div:nth-child(3) {
  border-radius: 20px !important;
}

.ant-picker-active-bar {
  display: none !important;
}

.map + div {
  margin-top: -10rem;
}

.map + div > div {
  display: none;
}


/*========================
Responsive styles =================
===========================*/
@media (max-width: 767px) {
  .map-modal .ant-modal-body {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
  }
}

@media (min-width: 680px) {
  .sm\:col-span-4 {
	  grid-column: span 4/span 4;
  }
}

@media (max-width: 680px) {
  /* .search-bar.mobile .ant-picker-range {
    justify-content: start;
    flex-direction: column;
    width: 100%;
  } */

  /* .search-bar.mobile .ant-picker-input:first-child {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
  } */


  .search-bar-mobile .select-location > div > div:first-child {
    padding-left: 0;
    margin-left: -3px;
  }

  .ant-picker-range-arrow {
    display: none !important;
  }

  .ant-picker-dropdown {
    width: calc(100% - 20px);
  }

  .ant-picker-panels {
    width: calc(100% - 20px);
    overflow-x: auto;
  }

  .ant-modal-wrap {
    background-color: #f7f7f7;
  }

  .ant-modal-wrap .search-bar-mobile {
    margin: 0;
    height: 100%;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 !important;
    background: #f7f7f7 !important;
    height: 100%;
    box-shadow: none !important;
  }

  .search-bar-mobile .ant-modal-footer {
    margin-left: -10px  ;
    margin-right: -10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
  }

  .ant-modal-wrap {
    overflow: hidden;
  }

  .search-bar-mobile .select-location > div + div{
    left: -40px !important;
  }

  .location-map .google-map-wrapper  >div:first-child >div:first-child {
    background: #fff !important;
  }
}


[data-braintree-id="number-field-error"],
[data-braintree-id="expiration-date-field-error"],
[data-braintree-id="cvv-field-error"],
[data-braintree-id="postal-code-field-error"]{
  font-family: 'Plus Jakarta Sans' !important;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
  background-color: white !important;
  border-radius: 8px !important;
  padding: 11px 16px !important;
  font-size: 16px !important;
  border: 1px solid #d9d9d9 !important;
  font-family: 'Plus Jakarta Sans' !important;
}
.braintree-sheet__content--form .braintree-form__field-group .braintree-form__label,
.braintree-sheet__content--form .braintree-form__field-group .braintree-form__descriptor {
  font-family: 'Plus Jakarta Sans' !important;
}
.braintree-form__notice-of-collection {
  @apply w-full pl-5  py-15 !text-16 rounded-10 !text-left;

}
.braintree-form__notice-of-collection a {
  color: rgba(0,0,0,.85);
}
.braintree-form__notice-of-collection a:hover {
  color: rgba(0,0,0,.85);
}
.ant-spin-dot-item {
  @apply !bg-supernova
}

.error-message {
  @apply text-sm text-red-600 relative top-1
}
.swiper-slide {
  width: fit-content !important;
}
/* Add this CSS to your stylesheet */
.mySwipper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit (Safari, Chrome) */
}

.mySwipper {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.text-greenish {
    --tw-text-opacity: 1;
    color: rgb(55 201 158/var(--tw-text-opacity))
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Responsive adjustment */
@media (min-width: 768px) {
  .line-clamp-responsive {
    -webkit-line-clamp: 3; /* Show 3 lines on larger screens */
  }
}

@media (max-width: 767px) {
  .line-clamp-responsive {
    -webkit-line-clamp: 2; /* Show 2 lines on mobile */
  }
}